import type { Entry } from '~store';

const rules = {
  forbiddenChars(text) {
    // const forbiddenSet = new Set([
    //   173, 11,
    // ]);
    const warnings = [];
    for (let i = 0; i < text.length; i++) {
      if (text.charCodeAt(i) >= 0 && text.charCodeAt(i) <= 31) {
        warnings.push({ char: i, msg: 'Forbidden Character', level: 0 });
      }
      // if (forbiddenSet.has(text.charCodeAt(i))) {
      //   warnings.push({ char: i, msg: 'Forbidden Character', level: 0 });
      // }
    }
    return warnings;
  },
  multiSpace(text) {
    const warnings = [];
    let spaceCount = 0;
    for (let i = 0; i < text.length; i++) {
      if (text[i] === ' ') spaceCount += 1;
      else {
        if (spaceCount > 1) {
          warnings.push({
            char: i - 1,
            msg: 'Multiple spaces are not allowed',
            level: 1,
          });
        }
        spaceCount = 0;
      }
    }
    return warnings;
  },
  lineSpacing(text) {
    const last = text.length - 1;
    const warnings = [];
    if (text[last] === ' ') {
      warnings.push({
        char: last,
        msg: 'Line might not end with a space',
        level: 1,
      });
    }

    if (text[0] === ' ') {
      warnings.push({
        char: 0,
        msg: 'Line might not start with a space',
        level: 1,
      });
    }
    return warnings;
  },
  HTMLEscape() {
    return [];
  },
};

const linters = Object.keys(rules).map(key => {
  return rules[key];
});

const lint = entries => {
  entries.forEach(entry => {
    const { value } = entry;
    entry.linted = [];
    linters.forEach(linter => {
      const results = linter(value);
      return results.forEach(res => {
        entry.linted.push({
          rule: linter.name,
          ...res,
        });
      });
    });
  });
};

export const lintEntry = (entry: Entry) => {
  const { value } = entry;
  const linted = [];

  if (typeof value !== 'string') return linted;

  linters.forEach(linter => {
    const results = linter(value);
    results.forEach(res => {
      linted.push({
        rule: linter.name,
        ...res,
      });
    });
  });

  return linted;
};

export const lintText = (text: string) => {
  const linted = [];
  if (typeof text !== 'string') return linted;

  linters.forEach(linter => {
    const results = linter(text);
    results.forEach(res => {
      linted.push({
        rule: linter.name,
        ...res,
      });
    });
  });

  return linted;
};

export default lint;
