import styled from '@emotion/styled';
import { parsers } from '@hydrant/lang-conversion';
import {
  Button,
  DialogContent,
  DialogTitle, FormControl, InputLabel, MenuItem,
  Select,
} from '@mui/material';
import download from 'downloadjs';
import { observer } from 'mobx-react';
import React from 'react';

const fileFormats = Object.keys(parsers);
fileFormats.push('html');

const ButtonWrapper = styled.section`
  align-self: flex-end;
  button ~ button {
    margin-left: 1em;
  }
`;

const DialogWrap = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  min-width: 480px;
  min-height: 200px;
  padding: 1em;
`;

const MessageWrapper = styled.p`
  background-color: #FF5722;
  color: white;
  padding: 16px;
  margin: 0;
`;

const Export = observer(({ file, onDone }) => {
  const [format, setFormat] = React.useState(fileFormats[0]);

  const handleChange = e => setFormat(e.target.value);

  const handleDownload = async () => {
    if (!file.loaded) {
      await file.load();
    }

    const content = await file.serializedEntriesAs(format);
    const blob = new Blob([content], { type: `text/${format}` });
    const name = [
      file.name,
      file.modifiedAnything ? '.unsaved' : '',
      `.${format}`,
    ].join('');
    download(blob, name, 'text/plain; charset=UTF-8');
    onDone();
  };

  return (
    <>
      {file.modifiedAnything && (
        <MessageWrapper>You are downloading an unsaved file!</MessageWrapper>
      )}
      <DialogWrap style={{ minHeight: '304px' }}>
        <DialogTitle>{`Exporting ${file.name}`}</DialogTitle>
        <DialogContent>
          <br />
          <FormControl fullWidth>
            <InputLabel id="export-format-select-label" htmlFor="format">Format</InputLabel>
            <Select
              labelId="export-format-select-label"
              label="Format"
              value={format}
              onChange={handleChange}
              inputProps={{
                name: 'format',
                id: 'format',
              }}
            >
              {fileFormats.map(f => <MenuItem value={f} key={f}>{f}</MenuItem>)}
            </Select>
          </FormControl>
        </DialogContent>
        <ButtonWrapper>
          <Button onClick={onDone}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownload}
          >
            Download
          </Button>
        </ButtonWrapper>
      </DialogWrap>
    </>
  );
});

export default Export;

