/* eslint-disable max-classes-per-file */
import { makeAutoObservable } from 'mobx';
import path from 'path';
import { partition, flatten, groupBy } from 'lodash-es';
import { canDeserialize } from '@hydrant/lang-conversion';
import api, { GitlabFile, GitlabAPITreeEntry } from '~/api-client';
import type { Config } from '~/../api/src/defs';
import picomatch from 'picomatch';

import { fromGitlabFile, File } from './files';

const DEFAULT_CONFIG = {
  version: 1,
  sources: ['src/assets/lang'],
  branch: 'master',
  _fallback: true,
};

export class ConfigError extends Error {
  constructor(error: Error) {
    super(error.message);
    Object.assign(this, error);
  }
}

const glNormalizePath = p => {
  let endsWithSlash = false;
  if (p.endsWith('/')) endsWithSlash = true;
  p = path.resolve('/', p);
  if (p[0] !== '/') return p;
  if (endsWithSlash) p += '/';
  return p.substr(1);
};

export default class Project {
  // from gitlab
  id;
  name = '';
  description = '';
  group;
  webUrl;
  path;
  avatarUrl;
  createdAt;
  lastActivityAt;

  // custom fields
  ref = 'master';
  isFavorite = false;
  // this is the ted.config.yaml
  config: Config = DEFAULT_CONFIG;
  files = [];
  environments = [];

  constructor(repo) {
    Object.assign(this, {
      id: repo.id,
      name: repo.name,
      description: repo.description,
      group: repo.group,
      avatarUrl: repo.avatar_url,
      webUrl: repo.web_url,
      path: repo.path_with_namespace,
      createdAt: new Date(repo.created_at),
      lastActivityAt: new Date(repo.last_activity_at),
      isFavorite: repo.isFavorite,
    });
    makeAutoObservable(this, {
      id: false,
    });
  }

  get localHref() {
    return `repo/${this.id}`;
  }

  get filePaths() {
    return this.files.map(file => file.path);
  }

  isFileDefaultLang(file: File) {
    const defaultLang = this.config.languages && this.config.languages[0];

    // check feature disabled
    if (!defaultLang) return false; // default lang not set or disabled
    const defaultLc = new RegExp(`${defaultLang || 'en'}$`, 'i');
    return (
      defaultLc.test(file.name)
      || file.gitlabFile.dir.split('/').includes(defaultLang || 'en')
    );
  }

  toggleFavorite() {
    this.isFavorite = !this.isFavorite;
  }


  async setConfig(config) {
    this.config = config;
  }


  async setFiles(files) {
    this.files = files;
  }

  async fetchConfig() {
    const remote = await api
      .get(`projects/${this.id}/config?ref=${this.ref}`)
      .then(res => ({
        ...res.data,
        _fallback: false,
      }))
      .catch(e => {
        if (e.response && e.response.status && e.response.status === 404) {
          console.warn('No config found. Using defaults');
          return {};
        }
        throw new ConfigError(e);
      });
    const config = { projectId: this.id, ...DEFAULT_CONFIG, ...remote };
    this.setConfig(config);
    return this.config;
  }

  async fetchEnvironments() {
    return api
      .get(`gitlab/projects/${this.id}/environments`)
      .then(res => res.data);
  }

  _fetchFolder = path => {
    let recursive = false;
    if (path.endsWith('/')) recursive = true;
    // TODO: pagination
    return api.get(`gitlab/projects/${this.id}/repository/tree`, {
      params: { path, per_page: 100, ref: this.ref, recursive },
    }).then(res => {
      return (res.data as GitlabAPITreeEntry[])
        .filter(f => canDeserialize(f.path))
        .map(f => new GitlabFile(this.id, f));
    });
  };

  async fetchFileList() {
    const ignoreMatch = picomatch(this.config.ignore || []);
    // removes leading slash and stuff
    const normalizedSources = this.config.sources.map(glNormalizePath);
    // split files from folders. assume everything that can be deserialized is a file
    const [files, folders] = partition(normalizedSources, canDeserialize);

    console.log(files, folders)

    // group common folders in file sources
    const fileGroups = groupBy(files, path.dirname);

    // do fetch the folders of the file sources
    const fileOps = Object.keys(fileGroups)
      .filter(dir => !folders.includes(dir)) // these will be fetched via folders (see below)
      .map(dir => {
        return this._fetchFolder(dir).then(files => {
          const allowed = fileGroups[dir];
          return files
            // filter out everything not wanted by the config
            .filter(f => allowed.includes(f.path))
            .filter(f => !ignoreMatch(f.path))
            .map(fromGitlabFile);
        });
      });

    // fetch the folders
    const folderOps = folders.map(async path => {
      const fetchedFiles = await this._fetchFolder(path);
      return fetchedFiles
        .filter(f => canDeserialize(f.path))
        .filter(f => !ignoreMatch(f.path))
        .map(fromGitlabFile);
    });

    // wait for promises & merge everything together
    const fileEntries = flatten(
      await Promise.all([...fileOps, ...folderOps]),
    );

    this.setFiles(fileEntries);
    return this.files;
  }
}
