import { makeAutoObservable } from 'mobx';
import api, { GitlabFile } from '~/api-client';
import type { AssetView } from '~/../api/src/defs';
import Asset from '../asset';
import { type Openable, type CommitInstructionFile, getCountryFromPath } from './core';

export default class AssetFile implements Openable, CommitInstructionFile {
  gitlabFile: GitlabFile;
  assets: Asset[] = [];

  loaded = false;
  parseError = null;
  includeInCommit = true;

  constructor(file: GitlabFile) {
    this.gitlabFile = file;

    makeAutoObservable(this);
  }

  get name() {
    return this.gitlabFile.name;
  }

  get country() {
    return getCountryFromPath(this.gitlabFile.path);
  }

  get uid() {
    return this.gitlabFile.uid;
  }

  get isParsable() {
    return true;
  }

  get modifyCount() {
    return this.modifiedAssets.length;
  }

  get modifiedAnything() {
    return this.modifyCount > 0;
  }

  get modifiedAssets() {
    return this.assets.filter(asset => asset.modified);
  }

  async getCommitActions() {
    const { modifiedAssets } = this;
    return modifiedAssets.map(asset => ({
      ted_upload_id: asset.ted_upload_id,
      file_path: asset.content.file_path,
      action: 'update',
    }));
  }

  async markCommitted() {
    this.assets.forEach(asset => asset.markCommitted());
  }

  async undoChanges() {
    this.assets.forEach(asset => asset.reset());
  }

  async load() {
    try {
      await this._load();
    } catch (e) {
      console.log('error loading asset file', e);
      this.parseError = e;
      this.loaded = true;
    }
  }

  async _load() {
    const { data } = await api.get('assets', {
      params: {
        projectId: this.gitlabFile.projectId,
        yamlFilePath: this.gitlabFile.path,
        ref: this.gitlabFile.ref,
      },
    });
    // temporary conversion from n AssetWithVariants to m AssetViews (with m >= n; m <= n * langs)
    const assetViews = (data as AssetView)
      .map(({ definition, gitMetadata, signedUrl, variants }) => {
        const hasVariants = variants && variants.length;
        const notFound = !gitMetadata || !signedUrl;
        if (!hasVariants) return { ...definition, ...gitMetadata, signed_url: signedUrl, notFound };
        return variants.map(({ language, gitMetadata, signedUrl }) => {
          const notFound = !gitMetadata || !signedUrl;
          return {
            ...definition, // just note (name, path overwritten below)
            name: `${definition.name} (${language.toUpperCase()})`,
            path: definition.path.replace(/{{language}}/g, language),
            ...gitMetadata,
            signed_url: signedUrl,
            notFound,
            language, // not in type TMP_FE_AssetView, but passed for reference
          };
        });
      })
      .flat(1);
    this.assets = assetViews
      .filter(asset => !asset.notFound)
      .map(a => new Asset(a));
    this.loaded = true;
  }
}
