import React, { useState } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

import { ButtonBase } from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { motion, AnimatePresence, MotionConfig } from 'framer-motion';

import ui from '~/store/tabs';
import sidebar from '~/store/sidebar';
import FileLink from '../../components/FileLink';
import { usePositionReorder, useMeasurePosition } from './utils';

const TabContainer = styled.div`
  position: relative;
  z-index: 20;
  padding-left: 10px;
  display: flex;
  width: 100%;
  font-size: 14px;
  padding-top: 6px;
  height: 44px;
  min-height: 44px;
  max-height: 44px;
  overflow: hidden;

  .contentWrap {
    flex: 1;
    display: flex;
    width: 100%;
    padding-right: 15px;
  }
`;

const Tab = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100px;

  background-color: transparent;
  max-width: 250px;
  min-width: 60px;
  height: 100%;
  padding-right: 3px;
  padding: 0 15px;
  position: relative;
  z-index: 2;

  .main {
    padding: 10px 5px;
    padding-left: 10px;
    flex: 1;

    max-width: 137px;
    overflow: hidden;
    line-height: 1;
  }

  &:hover {
    background: var(--theme-alt-panel);
    --background: var(--theme-alt-panel);
    z-index: 0;

    .close { opacity: 1 }
  }

  .close {
    height: 22px;
    width: 22px;
    margin-bottom: 2px;
    border-radius: 100%;
    font-size: 16px;
    svg { font-size: inherit; }
    opacity: 0;
  }

  .close:hover {
    background-color: var(--theme-bg);
  }

  --background: transparent;
  --corner-radius: 10 10 -10 -10;

  &[data-active] {
    .close { opacity: 1 };
    background: var(--theme-panel);
    --background: var(--theme-panel);
    filter: drop-shadow(0 -1px 1px var(--theme-box-shadow-light));
    z-index: 3;
  }

  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  margin-right: -10px;
  @supports (background: paint(something)) {
    background: paint(inverted-corners) !important;
    margin-right: -25px;
  }
`;

const TabElement = ({ tab, active, onClick, onClose, onUpdatePosition, onUpdateOrder }) => {
  const [isDragging, setDragging] = useState(false);
  const ref = useMeasurePosition(onUpdatePosition);
  // middle button click
  const handleAuxClick = e => {
    if (e.button !== 1) return;
    e.preventDefault();
    onClose();
  };

  const handleCloseClick = e => {
    e.preventDefault();
    e.stopPropagation();
    if (onClose) onClose(e);
  };

  // only left button click
  const handleClick = e => {
    if (e.button !== 0) return;
    e.preventDefault();
    onClick(e);
  };

  const zIndex = (() => {
    if (active) return 3;
    if (isDragging) return 4;
    return 2;
  })();

  return (
    <motion.div
      className="min-w-2"
      layout
      drag="x"
      initial={{ x: -10, opacity: 0, scale: 0.9 }}
      animate={{ x: 0, opacity: 1, scale: 1, transition: { duration: 0.1 } }}
      exit={{ x: -1, opacity: 0, scale: 0.9, transition: { duration: 0.1 } }}
      onDragStart={() => setDragging(true)}
      onDragEnd={() => setDragging(false)}
      ref={ref}
      onViewportBoxUpdate={(_viewportBox, delta) => {
        if (isDragging) onUpdateOrder(delta.x.translate);
      }}
      style={{
        zIndex,
      }}
    >
      <Tab
        className="tab"
        data-active={active ? true : undefined}
        data-tab-file={tab.file.name}
        onAuxClick={handleAuxClick}
        onMouseDown={handleClick}
      >
        <FileLink
          className="main truncate"
          file={tab.file}
          short
        >
          {tab.file.name}
        </FileLink>
        <ButtonBase
          className="close"
          size="small"
          onClick={handleCloseClick}
        >
          <CloseIcon />
        </ButtonBase>
      </Tab>
    </motion.div>
  );
};

export default observer(() => {
  const handleMove = (i, targetIndex) => {
    ui.moveTabIndex(i, targetIndex);
  };

  const setActive = tab => {
    ui.activeTab = tab;
  };

  const [updatePosition, updateOrder] = usePositionReorder(handleMove);
  const tabs = ui.tabs.filter(t => t);

  return (
    <MotionConfig transition={{ type: 'spring', duration: 0.25, bounce: 0.3, ease: 'easeInOut' }}>
      <TabContainer
        style={{ maxWidth: sidebar.isOpen ? 'calc(100vw - 400px)' : 'calc(100vw - 100px)' }}
      >
        <div className="contentWrap">
          <AnimatePresence>
            {tabs.map((tab, index) => {
              return (
                <TabElement
                  key={tab.file.uid}
                  tab={tab}
                  onUpdateOrder={e => updateOrder(index, e)}
                  onUpdatePosition={e => updatePosition(index, e)}
                  active={tab === ui.activeTab}
                  onClick={() => setActive(tab)}
                  onClose={() => ui.removeTab(tab)}
                />
              );
            })}
          </AnimatePresence>
        </div>
      </TabContainer>
    </MotionConfig>
  );
});
