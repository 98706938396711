import styled from '@emotion/styled';
import {
  Button, LinearProgress, TextField, Tooltip,
} from '@mui/material';
import { EditTwoTone } from '@mui/icons-material';
import localforage from 'localforage';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import store from '~/store';
import FileLink from '../../components/FileLink';
import { SidebarBox } from './utils';

const ChangeListWrap = styled.ul`
  margin: 0;
  padding: 0;

  background-color: #0000000a;
  border-radius: 5px;
  margin: 3px;
  margin-top: -1px;
`;

const ChangeEntryWrap = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;

  & > div {
    display: flex;
    align-items: center;
  }

  .start {
    flex:1;
  }

  .start input {
    margin-right: 8px;
    margin-left: 5px;
  }

  .end {
    display: flex;
    align-items: center;
  }

  .end > span::after {
    content: ",";
    color: var(--theme-light-text);
  }

  .end > span:last-of-type::after {
    content: "";
  }

  .changeCount { color: var(--theme-warn); }
  .problemCount { color: var(--theme-danger); }

  .end svg {
    font-size: 14px;
  }
`;

const ProblemBox = styled.div`
  color: var(--theme-danger);
  font-size: 14px;
  background-color: #ff00002b;
  border-radius: 12px;
  padding: 16px;
  margin: 10px 0;
`;

const EmptyStateWrap = styled(SidebarBox)`
  text-align: center;
  padding: 10px;

  .icon {
    font-size: 42px;
    margin: 20px 0;
  }

  p {
    opacity: 0.8;
  }
`;

const EmptyState = () => {
  return (
    <EmptyStateWrap className="EmptyState">
      <h3>Make some changes first</h3>
      <EditTwoTone className="icon" />
      <p>Edit some files, then return here to publish them to GitLab</p>
    </EmptyStateWrap>
  );
};

const ChangeFileEntry = observer(({ file }) => {
  let title = `${file.modifyCount} changes`;
  if (file.criticalProblemCount > 0) {
    title = `${file.criticalProblemCount} critical problems, ${title}`;
  }

  return (
    <ChangeEntryWrap key={file.uid}>
      <div className="start">
        <input
          type="checkbox"
          checked={file.includeInCommit}
          onChange={e => file.includeInCommit = e.target.checked}
        />
        <FileLink
          onAuxClick={() => store.openFile(file, { focus: false })}
          onClick={() => store.openFile(file)}
          file={file}
          title={file.gitlabFile.path}
        />
      </div>
      <Tooltip title={title}>
        <div className="end">
          {file.criticalProblemCount > 0 && (
            <span className="problemCount">{file.criticalProblemCount}</span>
          )}
          <span className="changeCount">{file.modifyCount}</span>
        </div>
      </Tooltip>
    </ChangeEntryWrap>
  );
});

const ChangedFileList = observer(() => {
  const { changedFiles } = store;

  return (
    <ChangeListWrap>
      {changedFiles.map(file => (
        <ChangeFileEntry file={file} key={file.uid} />
      ))}
    </ChangeListWrap>
  );
});

const DialogWrap = styled.div`
  .input {
    padding: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .input > .MuiFormControl-root {
    margin: 5px 0;
  }

  .save {
    padding: 25px;
    padding-top: 5px;
  }
`;

// const Label = styled.label`
//   white-space: nowrap;
// `;

const MaybeProgress = ({ visible }) => (!visible
  ? <div style={{ height: '5px' }} /> // placeholder
  : <LinearProgress />);

MaybeProgress.propTypes = {
  visible: PropTypes.bool,
};

MaybeProgress.defaultProps = {
  visible: false,
};

export default observer(() => {
  const summaryField = useRef();
  const [autofocused, setAutofocused] = useState(false);
  const [saving, setSaving] = useState(false);
  const [openedOnce, setOpenedOnce] = useState(false);

  const toSave = store.changedFiles.filter(f => f.includeInCommit);
  const isSingleFileChange = toSave.length === 1;

  // autofocus the summary field
  useEffect(() => {
    if (autofocused) return;
    if (!summaryField.current) return;
    summaryField.current.focus();

    setAutofocused(true);
  }, [summaryField.current]);

  const commit = () => {
    const { summary, description } = store.sidebar.commit;
    setSaving(true);

    let message = '📝 ';
    if (isSingleFileChange) message += `${toSave[0].name}: `;
    message += summary;

    // add description if there is any
    if (description) message += `\n\n${description}`;
    return store.commitChanges({ message, files: toSave }).catch(async e => {
      // remote update error
      if (e.response && e.response.status && e.response.status === 400) {
        // merge all remote changes
        await Promise.all(toSave.map(f => f.mergeRemote()));
        // if any of the files have conflicts return
        if (toSave.some(f => f.hasConflicts)) {
          toast.warn('Merge conflicts. Please resolved before saving', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          return true; // has conflicts
        }

        // otherwise try to commit again
        commit();
        return true;
      }
      if (e.response && e.response.status === 403) {
        toast.error('Missing write permission. Ask the repo owner for rights.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        toast.error(`🐞 ${e.toString()}.\nPlease report if this persists`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      throw e;
    }).then(abort => {
      if (abort === true) {
        setSaving(false);
        return;
      }
      // TODO: currentFile should not be used here
      const { uid } = store.currentFile;
      store.currentFile.lastAutosave = {};
      localforage.removeItem(`ted.autosave.${uid}.content`);
      localforage.removeItem(`ted.autosave.${uid}.meta`);
      toast.success('Changes saved successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      store.sidebar.setCommit({ summary: '', description: '' });
      setSaving(false);
    });
  };

  // const StartAdornment = () => (
  //   <InputAdornment position="start">
  //     <Label style={{ color: 'gray' }} htmlFor="summary">{`📝 ${toSave[0].name}:`}</Label>
  //   </InputAdornment>
  // );

  if (store.changedFiles.length === 0) {
    return <EmptyState />;
  }

  const problemNeedToBeResolved = toSave.some(f => f.hasConflicts);
  const { summary, description } = store.sidebar.commit;

  return (
    <SidebarBox title="Publish changes">
      <MaybeProgress visible={saving} />
      <DialogWrap>
        <div>
          <ChangedFileList />
        </div>
        <div className="input">
          <TextField
            inputRef={summaryField}
            id="summary"
            value={summary}
            onChange={e => store.sidebar.setCommitSummary(e.target.value)}
            onBlur={() => setOpenedOnce(true)}
            onKeyUp={e => (summary.length && e.keyCode === 13) && commit()}
            // InputProps={{ startAdornment: isSingleFileChange ? <StartAdornment /> : null }}
            error={summary === '' && openedOnce !== true}
            label="Short Summary"
            placeholder="(Required)"
            variant="outlined"
            required
          />
          <TextField
            id="description"
            multiline
            maxRows="6"
            value={description}
            onChange={e => store.sidebar.setCommitDescription(e.target.value)}
            placeholder="optional"
            label="Description"
            variant="outlined"
          />
          {/* <FormControlLabel
            control={<Checkbox value="checkedA" />}
            label="Request Review" /> */}
        </div>
        <div className="save">
          {problemNeedToBeResolved && (
            <ProblemBox>
              Files marked in red can not be saved because of critical problems.
              Please resolve them.
            </ProblemBox>
          )}
          <Button
            variant="outlined"
            color="primary"
            onClick={commit}
            disabled={!summary.length || problemNeedToBeResolved || toSave.length === 0}
          >
            Publish
          </Button>
        </div>
      </DialogWrap>
    </SidebarBox>
  );
});
