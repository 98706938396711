import styled from '@emotion/styled';
import { Popover } from '@mui/material';
import React, { useState } from 'react';

const ContextWrap = styled.div`
  background-color: var(--theme-panel);
  border-radius: 9px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.04);
  padding: 10px;
  overflow: hidden;
`;

export const ContextItem = styled.button`
  display: flex;
  padding: 8px;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  background-color: transparent;
  border: 0 none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  .MuiSvgIcon-root {
    margin-right: 10px;
  }

  &:hover {
    background-color: #8585852e;
  }

  &:active {
    background-color: #85858570;
  }

  opacity: ${p => p.disabled ? 0.5 : 1};
`;

// hook that allows to open a context menu
export default menu => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  // handle right click
  const onClick = e => {
    e.preventDefault();
    setPosition({ x: e.clientX - 2, y: e.clientY - 4 });
    setIsOpen(!isOpen);
  };

  const onClose = e => {
    e.stopPropagation();
    setIsOpen(false);
  };

  const Rendered = () => {
    if (!isOpen) return null;

    return (
      <Popover
        open
        onClose={onClose}
        onAbort={onClose}
        anchorReference="anchorPosition"
        anchorPosition={
          position.y !== null && position.x !== null
            ? { top: position.y, left: position.x }
            : undefined
        }
      >
        <ContextWrap onClick={onClose} className="context-menu">
          {menu}
        </ContextWrap>
      </Popover>
    );
  };

  return [onClick, Rendered];
};
