import React, { useEffect } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';

import store from '../store';
import ListItem, { LoadingPlaceholder } from './ListItem';
import FilterBar from './FilterBar';
import emptySvg from '../assets/undraw_empty_xct9.svg';

/**
 * Displays a list of repositories
 */
const RepoList = observer(() => {
  useEffect(() => {
    if (!store.projects.hasProjects) {
      store.projects.fetchProjects();
    }

    return autorun(() => {
      const favs = store.projects.favorites.map(f => f.id);
      window.localStorage.setItem('ted.favorites', JSON.stringify(favs));
    });
  }, []);

  return (
    <div className="container mx-auto text-center font-sans">
      <FilterBar />

      <div className="shadow-lg bg-panel rounded-lg overflow-hidden mx-2 mb-4">
        {!store.projects.loaded
          && [...Array(8).keys()].map(i => <LoadingPlaceholder key={i} />)
        }
        {store.projects.loaded && store.projects.projects.length === 0 && (
          <div className="flex flex-col justify-center items-center p-5 my-4">
            <img src={emptySvg} alt="" className="w-64 mb-8" />
            You don&apos;t have access to any projects (yet).
          </div>
        )}
        {store.projects.hasProjects && store.projects.filteredProjects.map(repo => (
          <ListItem
            data-project-id={repo.id}
            key={repo.id}
            repo={repo}
          />
        ))}
      </div>
    </div>
  );
});

export default RepoList;
