import './styles/index.css';

import React from 'react';
import 'normalize.css';
import * as Sentry from '@sentry/react';
// taking advantage of automatic instrumentation ("highly recommended")
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { render } from 'react-dom';
import axios from 'axios';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { useAsync } from 'react-use';

// notifications
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

// Store
import { configure } from 'mobx';
import { observer } from 'mobx-react';

// Routing
import { Router, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { createHashHistory } from 'history';

import { ThemeProvider, createTheme } from '@mui/material';
import { StylesProvider } from '@mui/styles';

import Loading from '~/project/Loading';
import store from './store';
import './store/autosave';

// Shell
import TedAppBar from './components/appbar/AppBar';

// Routes
// import Repo from './repo';
import Project from './project';

import RepoList from './repoList';
import Nothing from './pages/Nothing';
import Login from './pages/Login';
import ErrorBoundary from './components/ErrorBoundary';
import SwUpdate from './components/SwUpdate';
import pkg from '../package.json';
import './service-worker';

const history = createHashHistory();

declare global {
  interface Window { DEPLOY_ENV: string; }
}

// first thing. init sentry
Sentry.init({
  dsn: process.env.NODE_ENV === 'production'
    ? 'https://ed4a631c7fd34acabec6d2bac1c854ed@sentry.io/235865'
    : null,
  release: `ted@${pkg.version}`,
  environment: window.DEPLOY_ENV || process.env.DEPLOY_ENV || process.env.NODE_ENV || 'local',
  integrations: [
    new TracingIntegrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 0.2,
});

// mobx plz be nice. can not rewrite the whole thing
configure({ enforceActions: 'never' });

axios.defaults.baseURL = 'https://gitlab.hydra-newmedia.cloud/api/v4';

const queryClient = new QueryClient();

const userFetchOp = store.fetchUser();

if ('paintWorklet' in CSS) {
  (CSS as any).paintWorklet.addModule(
    'https://unpkg.com/inverted-corners/lib/inverted-corners-worklet.min.js',
  );
}

const PrivateRoute = observer(({ component: Component, ...rest }) => {
  const { user } = store;
  const location = useLocation();
  const fetchState = useAsync(() => userFetchOp);

  if (fetchState.loading) return <Loading message="Loading TED" />;

  if (!user) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={props => (
        <ErrorBoundary>
          <Component {...props} />
        </ErrorBoundary>
      )}
    />
  );
});

const App = observer(() => {
  const { themeVariant } = store.userSettings;

  const theme = createTheme({
    palette: {
      // primary: { main: '#2196f3' },
      // secondary: { main: '#E91E63' },
      mode: themeVariant,
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <>
              {store.userIsAuthenticated && <TedAppBar />}
              <ErrorBoundary>
                <Switch>
                  <PrivateRoute path="/" exact component={RepoList} />
                  <Route path="/login" exact component={Login} />
                  <PrivateRoute path="/repo/:id/:filePath*" component={Project} />
                  <Route component={Nothing} />
                </Switch>
              </ErrorBoundary>
            </>
          </Router>
          <ToastContainer theme={store.userSettings.themeVariant} position="bottom-center" />
          <SwUpdate />
        </ThemeProvider>
      </StylesProvider>
    </QueryClientProvider>
  );
});

render(<App />, document.getElementById('app'));
