/* eslint-disable react/prop-types */
import styled from '@emotion/styled';
import { Button, LinearProgress } from '@mui/material';
import { debounce } from 'lodash-es';
import FlyList from '@wombats/fly-list';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { AssetFile, LanguageFile } from '~/store/modules/files';
import store, { Entry, FileTab } from '../store';
import filterStore from '../store/filters';
import EditableEntry from './entry/EditableEntry';
import helloSvg from '../assets/undraw_Hello_re_3evm.svg';
import emptySvg from '../assets/undraw_empty_xct9.svg';
import blankSvg from '../assets/undraw_blank_canvas_3rbb.svg';
import AssetEdit from './asset-edit';

import { FileError, CenterMessage } from './Utils';


const EmptyState = () => (
  <div className="flex justify-center items-center flex-col">
    <img src={helloSvg} alt="" className="w-2/3 md:w-1/2 p-8 max-w-lg" />
    <p className="text-2xl">Open a file on the left to start using TED.</p>
  </div>
);


const EmptyFile = ({ file }) => {
  const handleCreate = () => {
    const newEntry = new Entry({
      toBeCreated: true,
      id: '',
    });
    file.entries.push(newEntry);
  };
  return (
    <CenterMessage>
      <img className="icon" src={blankSvg} alt="" />
      <div>This file is empty</div>
      <p>
        Create the first entry by using the button below, or open this file in an external editor.
      </p>
      <div>
        <Button
          color="secondary"
          variant="outlined"
          onClick={handleCreate}
        >
          Create first entry
        </Button>
      </div>
    </CenterMessage>
  );
};

const EmptyFilteredFile = observer(() => {
  const handeClick = () => {
    store.sidebar.setActiveItem('filter');
  };
  return (
    <CenterMessage>
      <img className="icon" src={emptySvg} alt="" />
      <div>You filtered out all the entries</div>
      <p>Change your filter to see some.</p>
      {store.sidebar.activeItem !== 'filter' && (
        <div>
          <Button color="secondary" variant="outlined" onClick={handeClick}>Change filter</Button>
        </div>
      )}
    </CenterMessage>
  );
});

const handleAppend = entry => {
  const file = store.currentFile;
  const newEntry = new Entry({
    toBeCreated: true,
    id: '',
  });
  file.entries.splice(file.entries.indexOf(entry) + 1, 0, newEntry);
};

const handlePrepend = entry => {
  const file = store.currentFile;
  const newEntry = new Entry({
    toBeCreated: true,
    id: '',
  });
  file.entries.splice(file.entries.indexOf(entry), 0, newEntry);
};

const handleAbortCreation = e => {
  const file = store.currentFile;
  file.entries.splice(file.entries.indexOf(e), 1);
};

const LanguageEdit = observer(({ file, scrollTop }: { file: LanguageFile, scrollTop: number }) => {
  const listRef = useRef(null);
  const { entries, filteredEntries } = file;

  const focusEntry = index => {
    const listEl = listRef.current;
    if (index < 0 || index > entries.length) return;
    listEl.focusIndex(index);
  };

  const debouncedSaveScroll = debounce(pos => {
    file.setScrollTop(pos);
  }, 200);

  useEffect(() => {
    reaction(() => store.findReplace.focusedIndex, index => {
      if (store.currentFile !== file) return;
      focusEntry(index);
    });
  }, [file]);


  if (entries.length === 0) return <EmptyFile file={file} />;
  if (filteredEntries.length === 0) return <EmptyFilteredFile />;

  return (
    <FlyList
      ref={listRef}
      style={{ height: 'calc(100vh - 73px)', contain: 'strict' }}
      className="custom-scrollbar"
      key={file.uid}
      cacheKey={file.uid}
      bgMeasure={15}
      onScroll={debouncedSaveScroll}
      // TODO: this somehow does not work for background tabs
      initialScroll={scrollTop}
      // onBgMeasureComplete={() => console.warn('bg measure complete')}
    >
      {filteredEntries.map((entry, index) => (
        <EditableEntry
          key={entry._key}
          entry={entry}
          file={file}
          index={index}
          onPrepend={() => handlePrepend(entry)}
          onAppend={() => handleAppend(entry)}
          onAbortCreation={handleAbortCreation}
          onFocusChange={focusEntry}
          readOnly={false}
        />
      ))}
    </FlyList>
  );
});

const SpinnerWrap = styled.div`
  margin: auto;
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiLinearProgress-root {
    width: 100%;
    max-width: 200px;
    margin: 20px;
  }
`;

const TabRenderer = observer(({ tab, scrollTop }: { tab: FileTab, scrollTop: number }) => {
  const { file } = tab;

  if (!file.loaded) {
    return <SpinnerWrap><LinearProgress /></SpinnerWrap>;
  }

  if (!file.isParsable) {
    return (
      <FileError
        name="TED can not open this file"
        message={`The file extension ${file.gitlabFile.fileType} is not supported.`}
      />
    );
  }

  if (file.parseError) {
    return (
      <FileError
        name="Could not parse this file"
        message="Please contact the developer of this project"
        error={file.parseError.toString()}
      />
    );
  }

  if (file instanceof AssetFile) {
    return <AssetEdit file={file} />;
  }

  return <LanguageEdit file={file} scrollTop={scrollTop} />;
});


const BackgroundRenderer = observer(({ tab }) => {
  const scrollTop = useRef(tab.file.scrollTop);

  return (
    <div
      style={{ display: tab.file === store.currentFile ? 'block' : 'none' }}
      data-file={tab.file.name}
    >
      <TabRenderer scrollTop={scrollTop.current} tab={tab} />
    </div>
  );
});

const EditAreaWrap = styled.div`
  background-color: var(--theme-panel);
  box-shadow: 0 0 2px var(--theme-box-shadow);
  border-radius: 10px;
  padding: 2px 0;

  /* Add a thumb */
  *::-webkit-scrollbar-thumb {
    border: 2.5px solid var(--theme-panel);
  }
`;

export default observer(({ style }) => {
  if (store.tabs.tabs.length === 0) {
    return <EmptyState />;
  }

  let tabs = store.tabs.tabs.filter(t => t instanceof FileTab) as FileTab[];
  if (filterStore.active.length > 0) {
    tabs = tabs.filter(t => t.file === store.currentFile);
  }

  return (
    <EditAreaWrap style={style}>
      {tabs.map(tab => (
        <BackgroundRenderer key={tab.file.uid} tab={tab} />
      ))}
    </EditAreaWrap>
  );
});
