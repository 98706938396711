import { useEffect, useRef } from 'react';
import { clamp, distance } from 'popmotion';

const buffer = 30;

export const findIndex = (i, xOffset, positions) => {
  let target = i;
  const { left, width } = positions[i];
  const bottom = left + width;

  // If moving down
  if (xOffset > 0) {
    const nextItem = positions[i + 1];
    if (nextItem === undefined) return i;

    const swapOffset = distance(bottom, nextItem.left + nextItem.width / 2) + buffer;
    if (xOffset > swapOffset) target = i + 1;

    // If moving up
  } else if (xOffset < 0) {
    const prevItem = positions[i - 1];
    if (prevItem === undefined) return i;

    const prevBottom = prevItem.left + prevItem.width;
    const swapOffset = distance(left, prevBottom - prevItem.width / 2) + buffer;
    if (xOffset < -swapOffset) target = i - 1;
  }

  return clamp(0, positions.length, target);
};

export function useMeasurePosition(update) {
  // We'll use a `ref` to access the DOM element that the `motion.li` produces.
  // This will allow us to measure its height and position, which will be useful to
  // decide when a dragging element should switch places with its siblings.
  const ref = useRef(null);

  // Update the measured position of the item so we can calculate when we should rearrange.
  useEffect(() => {
    update({
      width: ref.current.offsetWidth,
      left: ref.current.offsetLeft,
    });
  });

  return ref;
}

export function usePositionReorder(onMove) {
  // We need to collect an array of height and position data for all of this component's
  // `Item` children, so we can later us that in calculations to decide when a dragging
  // `Item` should swap places with its siblings.
  const positions = useRef([]).current;
  const updatePosition = (i, offset) => (positions[i] = offset);

  // Find the ideal index for a dragging item based on its position in the array, and its
  // current drag offset. If it's different to its current index, we swap this item with that
  // sibling.
  const updateOrder = (i, dragOffset) => {
    const targetIndex = findIndex(i, dragOffset, positions);
    if (targetIndex !== i) onMove(i, targetIndex);
  };

  return [updatePosition, updateOrder];
}

