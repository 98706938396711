import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import store from '../store';
import EditArea from './edit-area';
import QuickSearch from './QuickSearch';
import Left from './sidebar';
import StatusBar from './StatusBar';
import EditTabs from './tabs';
import Actions from './Actions';
import Loading from './Loading';

const Wrap = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;

  .sideBarWrap {
    min-height: calc(100vh - 42px);
    display: flex;
    flex-direction: column;
  }

  nav {
    width: 64px;
  }

  aside {
    width: 300px;
    border-bottom-right-radius: 4px;
    padding-top: 1px;
  }

  main {
    height: calc(100vh - 32px);
    margin: 0 8px;
  }
`;

export default observer(() => {
  const params = useParams();

  const data = useAsync(async () => {
    return store.openProject(params.id);
  }, [params.id, params.filePath]);

  if (data.loading) return <Loading message="Loading …" />;
  if (data.error) throw data.error;

  return (
    <Wrap>
      <QuickSearch />
      <div className="sideBarWrap z-40 bg-inherit bg-theme rounded sticky top-0 left-0">
        <Left />
      </div>
      <main>
        <EditTabs />
        <EditArea style={{ flex: 1 }} />
      </main>
      <StatusBar />
      <Actions />
    </Wrap>
  );
});
