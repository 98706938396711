/* eslint-disable max-classes-per-file */
import { makeObservable, observable, action } from 'mobx';
import move from 'array-move';
import { File, LanguageFile } from './modules/files';

class Tab {
  id = '';
  label = '';

  constructor(tab) {
    Object.assign(this, tab);
  }
}

export class FileTab extends Tab {
  @observable file: File = null;

  get scrollTop() {
    if (this.file instanceof LanguageFile) {
      return this.file.scrollTop;
    }
    return 0;
  }

  constructor(tab) {
    super(tab);
    this.file = tab.file;
    makeObservable(this);
  }
}

class Store {
  @observable tabs: Tab[] = [];
  @observable activeTab = null;

  constructor() {
    makeObservable(this);
  }

  @action removeTab(tab) {
    const index = this.tabs.indexOf(tab);
    if (index > -1) {
      this.tabs.splice(index, 1);
    }
    if (tab === this.activeTab) {
      const newActiveTab = this.tabs[index > 0 ? index - 1 : 0];
      if (newActiveTab) this.activeTab = newActiveTab;
      else this.activeTab = null;
    }
  }

  @action moveTabIndex(oldIndex, index) {
    this.tabs = move(this.tabs, oldIndex, index);
  }

  @action openFileTab(file: File, focus = true) {
    const existingTab = this.tabs.find(t => t.id === file.uid);
    if (existingTab) {
      this.activeTab = existingTab;
      return;
    }

    const newTab = new FileTab({
      id: file.uid,
      label: file.name,
      file,
    });
    newTab.file.load();
    this.tabs.push(newTab);
    console.debug('openFileTab', newTab);

    // set tab if focus is requested or this is the only file
    if (focus || this.activeTab === null) {
      this.activeTab = newTab;
    }
  }
}

const store = new Store();
export default store;
