import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import Asset from '~store/modules/asset';
import invariant from 'tiny-invariant';
import ReactCrop, {
  makeAspectCrop,
  centerCrop,
  Crop,
  PercentCrop,
} from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import { AnimatePresence, motion } from 'framer-motion';
import { WarningAmberOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  const crop = centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );

  return crop;
}

const WarningBox = ({ children }: { children: React.ReactNode }) => (
  <motion.div
    key="check-failed"
    initial={{ opacity: 0, y: 150, scale: 0.8 }}
    animate={{ opacity: 1, y: 0, scale: 1 }}
    exit={{ opacity: 0, y: 150, scale: 0.5 }}
    className="flex gap-2 rounded-full items-center border-orange-200 border bg-orange-300 p-2 pr-8
    text-orange-900 shadow h-16"
  >
    <motion.div initial={{ scale: 0, rotate: -90 }} animate={{ scale: 1, rotate: 0 }} transition={{ delay: 0.15 }}>
      <WarningAmberOutlined
        className="text-orange-700 !text-5xl w-16 p-2 mr-2 bg-orange-200 rounded-full"
      />
    </motion.div>
    <div className="flex flex-col gap-1">
      {children}
    </div>
  </motion.div>
);

const AnimatePresenceCompileTypescript = AnimatePresence as any;

const SizeCheck = ({ file, crop }: { file: Asset, crop: Crop }) => {
  let { width, height } = crop;
  // convert percentage to pixel
  width = Math.round((width / 100) * file.currentMetaData.width);
  height = Math.round((height / 100) * file.currentMetaData.height);

  let content = null;

  if (file.currentMetaData.width < file.originalAsset.width || file.currentMetaData.height < file.originalAsset.height) {
    content = (
      <WarningBox>
        <strong>Result might look blurry / pixelated.</strong>
        <small>Upload a higher resolution image if possible.</small>
      </WarningBox>
    );
  } else if (width === 0 || height === 0) {
    content = (
      <WarningBox>
        <strong>Missing selection</strong>
        <small>Drag a box to select what to crop.</small>
      </WarningBox>
    );
  } else if (width < file.originalAsset.width || height < file.originalAsset.height) {
    content = (
      <WarningBox>
        <strong>Result might look blurry / pixelated.</strong>
        <small>Select a larger area if possible.</small>
      </WarningBox>
    );
  }

  return (
    <AnimatePresenceCompileTypescript>
      {content}
    </AnimatePresenceCompileTypescript>
  );
};

export default function CropDialog({ file }: { file: Asset }) {
  const aspect = file.originalAsset.width / file.originalAsset.height;
  const [cropping, setCropping] = useState(false);
  const [crop, setCrop] = useState<Crop>(() => {
    const percentCrop = centerAspectCrop(
      file.currentMetaData.width,
      file.currentMetaData.height,
      aspect,
    );
    return percentCrop;
  });

  const src = file.preview;
  invariant(src, 'file needs to have a preview');

  const doTheCrop = async () => {
    setCropping(true);
    await file.serverCrop(crop as PercentCrop);
    setCropping(false);
  };

  const cancelCrop = () => {
    file.reset();
  };

  const width = Math.round((crop.width / 100) * file.currentMetaData.width);
  const height = Math.round((crop.height / 100) * file.currentMetaData.height);

  const noSelection = !crop || crop.width === 0 || crop.height === 0;

  return (
    <Dialog className="relative z-50" static open onClose={console.log}>
      <div className="fixed inset-0 bg-slate-900/95 backdrop-blur-md" aria-hidden="true" />
      <div className="fixed inset-0 flex w-full h-screen" id="crop-image-dialog">
        <Dialog.Panel className="flex flex-col flex-1 h-screen max-h-screen">
          <Dialog.Title className="w-full bg-slate-700 py-4 text-white text-lg shadow-md border-b-slate-600">
            <div className="mx-auto container px-6">Replace {file.content.file_name}</div>
          </Dialog.Title>
          <div className="mx-auto max-w-screen-2xl flex justify-center items-center flex-1 p-2">
            <ReactCrop
              aspect={aspect}
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              className="max-h-[calc(100vh-160px)]"
            >
              <img className="object-contain" src={src} alt="" />
            </ReactCrop>
          </div>
          <div className="container mx-auto flex justify-end items-center gap-3 shadow-lg bg-slate-700/50 rounded-lg m-2 p-4 px-6 h-20 flex-shrink-0">
            <motion.div className="flex-1 flex justify-center">
              <SizeCheck file={file} crop={crop} />
            </motion.div>
            <div className="text-white opacity-50 text-sm mr-6">
              <div>Min. size: {file.originalAsset.resolution}</div>
              <div>Current: {`${width} ✕ ${height}`}</div>
            </div>
            <Button onClick={cancelCrop}>
              Cancel
            </Button>
            <LoadingButton
              size="large"
              type="button"
              color="primary"
              variant="contained"
              onClick={doTheCrop}
              loading={cropping}
              disabled={noSelection}
            >
              Crop
            </LoadingButton>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
