import Axios from 'axios';
import parsePath from 'path-parse';

const baseURL = (window as any).API_URL
  || process.env.API_URL
  || (window.location.hostname !== 'localhost'
    ? `https://${window.location.hostname}/api/v1/`
    : undefined)
  || 'https://ted.dev.hydra-newmedia.cloud/api/v1/';

const api = Axios.create({
  baseURL,
  withCredentials: true,
});


export const getLoginURL = () => {
  const url = new URL(baseURL);
  url.pathname = '/api/v1/login';
  return url.toString();
};

export const getLogoutURL = () => {
  const url = new URL(baseURL);
  url.pathname = '/api/v1/logout';
  return url.toString();
};

export const setApiToken = token => {
  if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common.Authorization;
  }
};

// authenticates using gitlab token
export const authWithGitLabToken = async token => {
  const { data } = await api.post('account/_gitlabTokenLogin', { token });
  setApiToken(data.token);
  return data;
};

// authenticates using firebase token
export const authWithFirebaseToken = async token => {
  const { data } = await api.post('account/_firebaseTokenLogin', { token });
  setApiToken(data.token);
  return data;
};

export const refreshAuthCookie = async () => {
  await api.post('account/_refreshAuthCookie');
};

export const logout = () => {
  return api.post('account/_logout');
};

export interface GitlabAPITreeEntry {
  id: string;
  mode: string;
  name: string;
  path: string;
  type: 'blob' | 'tree';
}

export interface GitlabAPIFile {
  blob_id: string;
  commit_id: string;
  content: string;
  content_sha256: string;
  encoding: string;

  file_name: string;
  file_path: string;

  last_commit_id: string;
  ref: string;
  size: number;
}

export class GitlabFile {
  projectId: string;

  blob_id: string;
  commit_id: string;
  content: string;
  content_sha256: string;
  encoding: string;

  file_name: string;
  file_path: string;

  last_commit_id: string;
  ref = 'master';
  size: number;

  constructor(projectId: string, file: GitlabAPITreeEntry | GitlabAPIFile) {
    this.projectId = projectId;
    if ('type' in file) {
      this.blob_id = file.id;
      this.file_name = file.name;
      this.file_path = file.path;
    } else {
      Object.assign(this, file);
    }
  }

  get path() {
    return this.file_path;
  }

  get name() {
    return this.file_name;
  }

  get uid() {
    return `${this.projectId}:${encodeURIComponent(this.path)}`;
  }

  get loaded() {
    return this.content;
  }

  get virtual() {
    return this.blob_id === undefined;
  }

  // some path helpers
  // TODO: this gets called way to often
  get parsedPath() {
    return parsePath(this.path);
  }

  get dir() : string {
    return this.parsedPath.dir;
  }

  get fileType() : string {
    return this.parsedPath.ext.substr(1);
  }

  /**
   * Fetches the file from the gitlab api, returns the raw response
   * and updates the object
   */
  async fetch(ref = this.ref) {
    const path = encodeURIComponent(this.path);
    const uri = `gitlab/projects/${this.projectId}/repository/files/${path}`;
    const { data } = await api.get(uri, {
      params: { ref },
    });
    const file = data as GitlabAPIFile;
    Object.assign(this, file);
    return file;
  }
}

export default api;
