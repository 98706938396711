/** @jsx jsx */
/* eslint-disable complexity, jsx-a11y/no-noninteractive-tabindex */

import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';
import withTheme from '@mui/styles/withTheme';
import ace from 'brace';

import {
  IconButton,
  Icon,
  Input,
  InputAdornment,
  Tooltip,
  Popper,
} from '@mui/material';

import { HotKeys, configure } from 'react-hotkeys';
import store from '~/store';
import filtersStore from '~/store/filters';
import Highlight from '../../components/Highlight';

import { LintBubble, LintList } from './LinterInfos';
import OverflowMenu from './OverflowMenu';
import Editor from './AceEditor';
import ConflictResolution from './ConflictResolution';

const { Range } = ace.acequire('ace/range');

configure({
  ignoreRepeatedEventsWhenKeyHeldDown: false,
});

const codeFont = css`
  font-weight: 400;
  line-height: 1.2;
`;

const RemoteInfo = styled.div`
  padding: 5px;
  padding-right: 10px;
  border-radius: 5em;
  background-color: ${props => (props.danger ? '#FF5722' : '#CFD8DC')};
  color: ${props => (props.danger ? 'white' : 'black')};
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  display: inline-flex;
  align-items: center;
  .icon { margin-right: 5px; }
`;

const IdInput = styled(Input)`
  margin-left: 14px;
  font-family: 'Source Code Pro', monospace;
  contain: content;
  width: 450px;
  .material-icons {
    cursor: pointer;
  }
`;

const EditorBox = withTheme(styled.div`
  position: relative;
  contain: style;

  .ace_editor {
    margin: 0;
    width: 100%;
    ${codeFont};
    background-color: transparent;
  }

  .linter {
    position: absolute;
    bottom: 5px;
    right: 5px;
    ${'' /* color: ${p => p.theme.palette.text.secondary}; */}
  }

  .lint-list {
    border-radius: 3px;

    &::after {
      content: "";
      position: absolute;
      top: -12px;
      right: 10px;
      height: 13px;
      background-color: #FF5722;
      width: 4px;
    }
  }
`);

const EditorWrap = styled.div`
  padding: 10px 6px;
  contain: content;
  overflow: hidden;
  width: 100%;
  padding-right: 15px;
  .ace_editor {
    contain: strict;
    backface-visibility: hidden;
    transform: translateZ(0);
  }
`;

const FakeEditor = withTheme(styled.div`
  ${codeFont};
  overflow-wrap: break-word;

  white-space: pre-wrap;
  tab-size: 2;
  padding: 10px;
  cursor: text;
  :after {
    opacity: 0.85;
    ${props => props.empty && 'content: "[no content]";'};
  }
`);

const Control = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  contain: content;
  /* justify-content: space-between; */

  .icon-bar {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
`;

const Title = styled.div`
  display: flex;
  opacity: 0.85;
  padding: 10px;
  font-weight: bold;
  font-size: 1em;
  flex: 0 1 auto;
  justify-self: flex-start;
  justify-content: center;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 30px;
  padding-left: 5px;
  position: relative;
  background-color: #8080801c;

  .material-icons {
    position: absolute;
    right: -20px;
    margin-left: 10px;
    font-size: 18px;
    opacity: 0;
    cursor: pointer;
  }
  :hover {
    .material-icons {
      opacity: 1;
    }
  }
`;

const DupKey = styled.small`
  background-color: #FF5722;
  padding: .4em .75em;
  border-radius: 1em;
  color: white;
`;

const wrapperStyle = css`
  margin: 5px auto;
  margin: 0 auto;

  max-width: 800px;
  min-width: 300px;

  background-color: var(--theme-panel);
  border: 2px solid transparent;

  &:focus-within {
    outline: none;
    border: 2px solid var(--theme-link-bg);
  }

  &:focus {
    outline: none;
    border: 2px solid var(--theme-link-text);
  }

  @media (max-width: 1550px) {
    margin: 0 10px;
  }
`;

const ReadValue = observer(({ entry, index }) => {
  const { replaced } = store;
  if (!store.findReplace.enabled) return entry.value;
  if (store.findReplace.find.value === '' || replaced instanceof Error || replaced.length === 0) {
    return entry.value;
  }

  const chunks = replaced[index];
  return (
    <Highlight chunks={chunks} />
  );
});

ReadValue.propTypes = {
  entry: PropTypes.object,
};

@withTheme
@observer
class EditableEntry extends React.Component {
  el = React.createRef();
  keyInput = React.createRef();
  state = {
    editingKey: false,
    hasFocus: false,
    hover: false,
    conflictResolutionOpen: false,
  };

  // hotkeys handler:key mapping
  keyMap = {
    enableEdit: 'e',
    disableEdit: 'esc',
    revertEverything: 'u',
    toggleRemoveEntry: 'del',
    appendEntry: 'a',
    prependEntry: 'shift+a',
    previousEntry: 'up',
    nextEntry: 'down',
  };

  // hotkeys handlers
  handlers = {
    enableEdit: e => {
      if (this.shortcutsActive()) {
        e.preventDefault();
        this.enableEdit(false);
      }
    },
    disableEdit: () => {
      if (!this.props.readOnly) {
        this.disableEdit();
        this.el.current.focus();
      }
    },
    revertEverything: () => {
      if (this.shortcutsActive()) this.handleUndo();
    },
    toggleRemoveEntry: () => {
      if (!store.limitedEditPermission && this.shortcutsActive()) {
        if (this.props.entry.toBeRemoved) this.props.entry.unmarkRemoved();
        else this.props.entry.markRemoved();
      }
    },
    appendEntry: () => {
      if (!store.limitedEditPermission && this.shortcutsActive() && !filtersStore.active.length) {
        this.props.onAppend(this.props.entry);
      }
    },
    prependEntry: () => {
      if (!store.limitedEditPermission && this.shortcutsActive() && !filtersStore.active.length) {
        this.props.onPrepend(this.props.entry);
      }
    },
    previousEntry: e => {
      if (!this.state.editing && document.activeElement === this.el.current) {
        e.preventDefault();
        this.props.onFocusChange(this.props.index - 1, e);
      }
    },
    nextEntry: e => {
      if (!this.state.editing && document.activeElement === this.el.current) {
        e.preventDefault();
        this.props.onFocusChange(this.props.index + 1, e);
      }
    },
  };

  componentDidMount() {
    if (this.props.entry.creationState === true && this.props.entry.imported !== true) {
      // great hack
      setTimeout(() => this.enableKeyEdit(), 300);
    }
  }

  // Handler
  handleChange = event => this.props.entry.setValue(event.target.value);
  colorChange = color => this.props.entry.setColor(color.hex);
  disableEdit = () => this.props.entry.setEditing(false);
  delayedDisableEdit = () => {
    this._disableTimeout = setTimeout(() => {
      this.props.entry.setEditing(false);
    }, 500);
  };
  enableEdit = (clicked = true) => {
    clearTimeout(this._disableTimeout);
    if (this.props.readOnly === true) return; // don't allow edit
    this.disableKeyEdit();
    const spookySel = window.getSelection();
    const selection = {
      start: spookySel.anchorOffset,
      end: spookySel.focusOffset,
      isRange: spookySel.anchorOffset !== spookySel.focusOffset,
    };

    this.props.entry.setEditing(true);
    this.setState({ hasFocus: true });
    requestAnimationFrame(() => {
      if (selection.isRange) {
        this._ace.selection.setRange(
          new Range(0, selection.start, 0, selection.end),
        );
      } else if (clicked) {
        this._ace.moveCursorTo(0, selection.end);
      } else {
        this._ace.navigateLineEnd();
      }
    });
  };
  enableKeyEdit = () => {
    if (this.props.readOnly === true) return; // don't allow edit
    this.disableEdit();
    this.setState({ editingKey: true });
  };
  disableKeyEdit = () => {
    if (!this.keyInput.current) return;
    this.props.entry.setId(this.keyInput.current.value);
    this.setState({ editingKey: false });
  };
  // Focus & Blur helper
  _onFocus = () => this.setState({ hasFocus: true });
  _onBlur = () => this.setState({ hasFocus: false });
  // hover helper
  _onMouseEnter = () => this.setState({ hover: true });
  _onMouseLeave = () => this.setState({ hover: false });
  _onKeyEditBlur = event => {
    const { entry } = this.props;
    const value = event.target.value.trim();
    this.setState({ editingKey: false });
    // save on blur
    if (value !== '') {
      if (entry.creationState === true) entry.setEditing(true);
      entry.setId(value);
      return;
    }
    // otherwise abort if this was a new entry
    if (entry.creationState === true) {
      // great hack 2
      setTimeout(() => this.props.onAbortCreation(entry), 250);
    }
  };

  handleUndo = () => {
    const { entry } = this.props;
    if (entry.toBeCreated === true) this.props.onAbortCreation(entry);
    else entry.revertEverything();
  };

  applyFindReplace = () => {
    const fr = store.findReplace;
    this.props.entry.findReplace(fr.find.value, fr.replace.value, fr.find);
  };

  shortcutsActive = () => {
    return !this.state.editing
      && !this.props.readOnly
      && document.activeElement === this.el.current;
  };

  renderTitle() {
    if (this.state.editingKey === true) {
      return (
        <IdInput
          autoFocus
          defaultValue={this.props.entry.id}
          id="key-edit-input"
          placeholder="entry_key"
          inputRef={this.keyInput}
          onBlur={this._onKeyEditBlur}
          endAdornment={(
            <InputAdornment onClick={this._onKeyEditBlur} position="end">
              <Icon>check</Icon>
            </InputAdornment>
          )}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              this._onKeyEditBlur(e);
            }
          }}
        />
      );
    }
    return (
      <Title style={{ textDecoration: this.props.entry.toBeRemoved && 'line-through' }}>
        {this.props.entry.id}
        {!store.limitedEditPermission && <Icon onClick={this.enableKeyEdit}>edit</Icon>}
      </Title>
    );
  }

  renderRemoteNotice() {
    const { entry } = this.props;
    if (entry.conflictsWith) {
      return (
        <RemoteInfo
          danger
          onClick={() => this.setState({ conflictResolutionOpen: true })}
        >
          <Icon className="icon">error</Icon>
          Resolve conflict
        </RemoteInfo>
      );
    }
    if (entry.remoteUpdated) {
      return (
        <RemoteInfo>
          <Icon className="icon">info_outline</Icon>
          &nbsp;Has been updated
        </RemoteInfo>
      );
    }
    return null;
  }

  renderIconBar() {
    const { entry, readOnly, onAppend } = this.props;

    return (
      <div className="icon-bar">
        {entry.modified && !entry.toBeRemoved && (
          <Tooltip title={entry.toBeCreated ? 'Abort creation' : 'Undo changes'}>
            <IconButton onClick={this.handleUndo} size="large">
              <Icon>{entry.toBeCreated ? 'delete' : 'undo'}</Icon>
            </IconButton>
          </Tooltip>
        )}
        {entry.toBeRemoved ? (
          <Tooltip title="Undo delete">
            <IconButton onClick={() => entry.unmarkRemoved()} size="large">
              <Icon>undo</Icon>
            </IconButton>
          </Tooltip>
        ) : !entry.creationState && (
          <>
            <Tooltip title="Highlight entry">
              <IconButton onClick={() => entry.toggleMark()} size="large">
                <Icon>flag</Icon>
              </IconButton>
            </Tooltip>
            {!store.limitedEditPermission && (
              <OverflowMenu
                onAppend={onAppend}
                entry={entry}
                readOnly={readOnly}
              />
            )}
          </>
        )}
      </div>
    );
  }

  render() {
    const { entry, index } = this.props;

    let borderColor = (entry.modifiedId || entry.modifiedValue) ? '#ffc107' : 'transparent';
    if (entry.toBeCreated) borderColor = '#8BC34A';
    if (entry.toBeRemoved) borderColor = '#F44336';

    const entryStyle = css`
      ${entry.meta.marked && 'background-color: var(--theme-highlight)'};
      opacity: ${entry.toBeRemoved ? 0.6 : 1};
      font-family: ${store.userSettings.displayFont};
      border-left: 3px solid ${borderColor};
      border-bottom: 1px solid var(--theme-alt-panel);

      .valueContent {
        font-size: ${store.userSettings.settings.entryFontSize || 16}px;
      }

      .ace_editor {
        font-size: inherit;
        font-family: 'Source Code Pro', monospace;
        color: inherit;
      }
    `;

    const fixFocus = e => {
      // big hack to identify hotkeys wrap element
      if (e.target.children[0] !== this.el.current) return;
      this.el.current.focus();
    };

    return (
      <HotKeys onFocusCapture={fixFocus} keyMap={this.keyMap} handlers={this.handlers}>
        <div
          /* eslint-disable-next-line react/no-unknown-property */
          css={wrapperStyle}
          style={{
            maxWidth: store.userSettings.settings.entryWidth === 1500
              ? '100%'
              : store.userSettings.settings.entryWidth,
          }}
          ref={this.el}
          onBlur={this._onBlur}
          onFocus={this._onFocus}
          onMouseEnter={this._onMouseEnter}
          onMouseLeave={this._onMouseLeave}
          tabIndex={0}
        >
          { /* eslint-disable-next-line react/no-unknown-property */ }
          <div css={entryStyle}>
            <Control>
              {this.renderTitle()}
              {this.props.file.duplicateKeys.includes(entry.id) && <DupKey>Duplicate key</DupKey>}
              {this.renderRemoteNotice()}
              {(this.state.hover || this.state.hasFocus) && this.renderIconBar(entry)}
            </Control>

            <EditorBox className="valueContent">
              {entry.editing
                ? (
                  <EditorWrap>
                    <Editor
                      onLoad={ace => this._ace = ace}
                      name={entry._key}
                      value={entry.value}
                      onChange={v => entry.setValue(v)}
                      onBlur={this.delayedDisableEdit}
                      onExit={this.handlers.disableEdit}
                    />
                  </EditorWrap>
                )
                : (
                  <FakeEditor empty={entry.value === ''} onClick={this.enableEdit}>
                    <ReadValue entry={entry} index={index} />
                  </FakeEditor>
                )}
              <LintBubble className="linter" entry={entry} />
              {entry.editing && (
                <Popper
                  open
                  id={`${entry._key}-linter`}
                  placement="bottom-end"
                  anchorEl={this.el.current}
                  // bad hack to get popper to always be up to date
                  key={JSON.stringify(entry.linterInfos)}
                >
                  <LintList className="lint-list" entry={entry} />
                </Popper>
              )}
            </EditorBox>
          </div>
          {this.state.conflictResolutionOpen && (
            <ConflictResolution
              entry={entry}
              onFinished={() => this.setState({ conflictResolutionOpen: false })}
            />
          )}
        </div>
      </HotKeys>
    );
  }
}

EditableEntry.propTypes = {
  index: PropTypes.number.isRequired,
  entry: PropTypes.object.isRequired,
  onAppend: PropTypes.func.isRequired,
  onAbortCreation: PropTypes.func.isRequired,
  onPrepend: PropTypes.func.isRequired,
  onFocusChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default EditableEntry;
