import { GitlabFile } from '~api-client';
import { countries } from "countries-list";

export interface Openable {
  gitlabFile: GitlabFile;
  name: string;
  uid: string;
  loaded: boolean;
  load(): Promise<void>
  markCommitted(id?: string)
}

interface CommitEntry {
  action: string,
  file_path: string,
  content?: string,
  last_commit_id?: string,
  ted_upload_id?: string,
}

export interface CommittableFile {
  getCommitContent: () => Promise<string>;
}

export interface CommitInstructionFile {
  getCommitActions: () => Promise<CommitEntry[]>;
}

function findCountryByLanguage(langOrCountry: string) {
  // use the last part if lang includes _
  if (langOrCountry.includes('_')) {
    langOrCountry = langOrCountry.split('_').pop();
  }

  // check if it's a country code
  const country = countries[langOrCountry.toUpperCase()];
  if (country) {
    return langOrCountry.toUpperCase();
  }

  // mappings for languages that don't match with the country name
  const defaultMappings = {
    en: 'US',
    da: 'DK',
    sv: 'SE',
    ja: 'JP',
    zh: 'CN',
    ko: 'KR',
    ar: 'SA',
    fa: 'IR',
  };


  if (defaultMappings[langOrCountry]) {
    return defaultMappings[langOrCountry];
  }

  // search through countries and find first that has this language
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const foundCountry = Object.entries(countries).find(([_, country]) => {
    return country.languages.includes(langOrCountry as any);
  });

  if (foundCountry) {
    return foundCountry[1];
  }

  return null;
}

export function getCountryFromPath(path: string) {
  const parts = path.split('/');

  // check if last folder is language
  const parentDirName = parts[parts.length - 2];
  const foundByParent = findCountryByLanguage(parentDirName);
  if (foundByParent) {
    return foundByParent;
  }

  // check if file name is language
  const fileName = parts[parts.length - 1].split('.').shift();
  const foundByFileName = findCountryByLanguage(fileName);
  if (foundByFileName) {
    return foundByFileName;
  }

  // check if file name starts with language (eg. de_)
  const fileNameMaybeLang = fileName.split('_')[0];
  const foundByFileNameMaybeLang = findCountryByLanguage(fileNameMaybeLang);
  if (foundByFileNameMaybeLang) {
    return foundByFileNameMaybeLang;
  }

  return null;
}
