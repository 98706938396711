import styled from '@emotion/styled';
import {
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Radio,
  Slider,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import Themed from '~/components/Themed';
import store from '~/store';
import pkg from '~/../package.json';
import { SidebarBox } from './utils';

const ThemePickWrap = styled.div`
  border: 1px solid var(--theme-light-text);
  padding: 5px;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px;
  cursor: pointer;

  .demo {
    background-color: var(--theme-bg);
    border-radius: 5px;
    overflow: hidden;
  }

  .panel {
    background-color: var(--theme-panel);
    border-radius: 6px;
    padding: 10px;
  }

  .altPanel {
    background-color: var(--theme-alt-panel);
    border-radius: 6px;
    padding: 10px;
  }

  .textBlobs > div {
    background-color: var(--theme-text);
    height: 16px;
    margin: 9px 26px;
    border-radius: 25px;
    opacity: 0.5;
  }

  &[data-is-active] {
    border-color: var(--theme-primary);
  }
`;

const ThemePickButton = observer(({ theme, onClick }) => {
  const isActive = store.userSettings.settings.theme === theme;
  return (
    <ThemePickWrap
      data-theme={theme}
      data-is-active={isActive ? true : undefined}
      onClick={() => onClick(theme)}
    >
      <Themed theme={theme}>
        <div className="demo">
          <div className="panel">
            <span>{theme}</span>
          </div>
          <div className="altPanel">
            <div className="textBlobs">
              <div />
              <div />
              <div />
            </div>
          </div>
        </div>
      </Themed>
    </ThemePickWrap>
  );
});

const SystemThemeBox = styled(FormGroup)`
  border: 1px solid var(--theme-light-text);
  padding: 5px;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px;
  cursor: pointer;

  &[data-is-active] {
    border-color: var(--theme-primary);
  }

  .MuiFormControlLabel-root {
    margin-left: 0;
  }

  .MuiFormHelperText-root {
    padding: 3px 10px;
  }
`;

const SystemThemePicker = observer(() => {
  const isActive = store.userSettings.settings.theme === 'system';

  const enable = () => {
    store.userSettings.settings.theme = 'system';
  };

  return (
    <SystemThemeBox
      onClick={enable}
      data-theme="system"
      data-is-active={isActive ? true : undefined}
    >
      <FormControlLabel
        control={<Radio checked={isActive} onChange={enable} />}
        about=""
        label="Use system theme"
      />
      <FormHelperText>
        When active, TED will use the theme specified by
        your operating system instead of a preselected one.
      </FormHelperText>
    </SystemThemeBox>
  );
});

const entryWidthMarks = [{
  value: 620,
  label: 'Default',
}, {
  value: 1500,
  label: 'Fill',
}];

const entryFontSizeMarks = [{
  value: 18,
  label: 'Default',
}];

export default observer(() => {
  const changeTheme = theme => {
    store.userSettings.setSetting('theme', theme);
  };

  const changeEntryWidth = (_, value) => {
    store.userSettings.setSetting('entryWidth', value);
  };

  const changeFontSize = (_, value) => {
    store.userSettings.setSetting('entryFontSize', value);
  };

  return (
    <div>
      <SidebarBox title="Theme">
        <div style={{ padding: 4 }}>
          <br />
          <ThemePickButton onClick={changeTheme} theme="light" className="lightThemePick" />
          <ThemePickButton onClick={changeTheme} theme="dark" className="darkThemePick" />
          {/* <ThemePickButton onClick={changeTheme} theme="hydra" /> */}
          <SystemThemePicker className="darkThemePick" />
        </div>
      </SidebarBox>
      <SidebarBox title="Entry style">
        <div style={{ padding: 20 }}>
          <Typography gutterBottom>
            Width
          </Typography>
          <Slider
            defaultValue={store.userSettings.settings.entryWidth}
            marks={entryWidthMarks}
            step={10}
            min={420}
            max={1500}
            onChangeCommitted={changeEntryWidth}
          />
          <br />
          <br />
          <Typography gutterBottom>
            Font size
          </Typography>
          <Slider
            defaultValue={store.userSettings.settings.entryFontSize}
            marks={entryFontSizeMarks}
            step={1}
            min={12}
            max={32}
            onChangeCommitted={changeFontSize}
          />
          <p style={{ opacity: 0.8 }}>Reload the page to apply these settings properly.</p>
        </div>
      </SidebarBox>
      <SidebarBox title="Version">
        <p style={{ padding: '4px 16px' }}>
          You are using TED &nbsp;
          <code>{pkg.version}</code>
        </p>
      </SidebarBox>
    </div>
  );
});
