import React from 'react';
import styled from '@emotion/styled';

import { observer } from 'mobx-react';
import { Tooltip } from '@mui/material';
import { DevicesOutlined, ErrorOutline, SaveOutlined } from '@mui/icons-material';
import { formatDistanceToNow } from 'date-fns';
import { LanguageFile } from '~/store/modules/files';
import store from '../store';

const StatusBar = styled.footer`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  padding: 1px;
  font-size: 14px;
  line-height: 0.5;
  align-items: center;

  border-radius: 13px;
  margin: 0 4px;
  margin-bottom: 0;
  width: calc(100% - 8px);
  height: 22px;
  padding: 0px 12px;

  justify-content: space-between;

  & > * {
    display: flex;
    align-items: center;
  }
`;


type StatusItemProps = {
  clickable?: boolean;
}

const StatusItem = styled.div<StatusItemProps>`
  display: flex;
  align-items: center;
  color: var(--theme-light-text);
  cursor: ${p => p.clickable ? 'pointer' : 'default'};

  .MuiSvgIcon-root {
    margin-right: 6px;
    font-size: 18px;
  }

  &:not(:last-of-type)::after {
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 100%;
    background-color: #70707094;
    margin-right: 10px;
    margin-left: 10px;
  }
`;

const ChangeCount = observer(props => {
  // TODO: calculate for all files
  const { changedFiles } = store;
  const total = changedFiles.map(f => f.modifyCount).reduce((a, b) => a + b, 0);

  if (total === 0) {
    return <div>No changes</div>;
  }
  return (
    <div style={{ color: 'var(--theme-warn)', cursor: 'pointer' }} {...props}>
      <span>{total}</span>
      <span>&nbsp;changes across&nbsp;</span>
      <span>{`${changedFiles.length} files`}</span>
    </div>
  );
});


@observer
class AutosaveNotice extends React.Component {
  interval = null;
  componentDidMount() {
    this.interval = setInterval(() => this.forceUpdate(), 2000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    const { lastAutosave, contentHash } = store.currentFile;
    if (!lastAutosave || !lastAutosave.date) return null;
    const date = formatDistanceToNow(lastAutosave.date, {
      includeSeconds: false,
    });

    const loaded = lastAutosave.justLoaded;

    const timeDiff = Math.abs(Date.now() - lastAutosave.date.valueOf());
    const probablySaving = timeDiff < 2000;
    const isUpToDate = lastAutosave.localHash === contentHash;
    const savingLag = !isUpToDate && !probablySaving;

    const style = {
      color: savingLag ? 'var(--theme-danger)' : 'inherit',
      transform: !isUpToDate && probablySaving ? 'scale(0.9)' : 'scale(1)',
      transition: 'color 0.5s ease 1s, transform 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28)',
      display: 'flex',
      alignItems: 'center',
    };

    const tooltipTitle = savingLag
      ? 'Autosave is slow! Maybe export this file to be safe.'
      : 'Last local autosave of this file';


    return (
      <Tooltip title={tooltipTitle}>
        <StatusItem>
          <div style={style}>
            <span className="title">
              &nbsp;
              {loaded ? <DevicesOutlined /> : <SaveOutlined />}
            </span>
            <span>
              {` ${date} ago`}
            </span>
          </div>
        </StatusItem>
      </Tooltip>
    );
  }
}

const FileStats = observer(() => {
  if (!store.currentEntries) return null;
  const { words, entries } = store.currentFile.stats;
  let text = `${words} words, ${entries} entries`;
  const filterCount = store.currentEntries.length;
  if (filterCount !== entries) text = `${text} (filtered to ${filterCount})`;

  return (
    <StatusItem>
      <span>{text}</span>
    </StatusItem>
  );
});

const FileProblems = observer(() => {
  const { problemCount } = store.currentFile;

  const handleClick = () => {
    store.filters.set('linterEntries', {
      enabled: !store.filters.get('linterEntries').enabled,
    });
  };

  return (
    <StatusItem clickable onClick={handleClick}>
      <ErrorOutline />
      <span>{problemCount}</span>
    </StatusItem>
  );
});

export default observer(() => {
  return (
    <StatusBar>
      <div className="leftSide">
        <ChangeCount onClick={() => store.sidebar.setActiveItem('save')} />
      </div>
      {store.currentFile && store.currentFile instanceof LanguageFile ? (
        <div className="rightSide">
          <AutosaveNotice />
          <FileProblems />
          <FileStats />
        </div>
      ) : <p style={{ color: 'var(--theme-alt-panel)' }}>You found an easter egg!</p>}
    </StatusBar>
  );
});
