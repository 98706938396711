import React from 'react';
import styled from '@emotion/styled';
import {
  FolderOutlined,
  FindReplaceOutlined,
  SettingsOutlined,
  FilterListOutlined,
  CloudUploadOutlined,
} from '@mui/icons-material';
import { observer } from 'mobx-react';
import { IconButton } from '@mui/material';

import store from '~/store';
import filtersStore from '~/store/filters';
import SideHeader from './SideHeader';
import SideFilepicker from './SideFilepicker';
import SideFindReplace from './FindReplace';
import SideFilter from './SideFilter';
import SideSave from './SideSave';
import SideSettings from './SideSettings';
import UserButton from '../../components/UserButton';
import PreviewButton from './PreviewButton';


const Nav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  padding-bottom: 10px;

  .end {
    align-self: end;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Separator = styled.div`
  height: 2px;
  width: 10px;
  background: #96969685;
  margin: 15px auto;
`;

const EntryBadgeWrap = styled.div`
  position: absolute;
  bottom: 4px;
  right: 2px;
  padding: 2px 5px;
  font-size: 12px;
  background-color: var(--theme-primary);
  color: var(--theme-bg);
  border-radius: 12px;
  min-width: 22px;
`;

const EntryBadge = ({ children }) => {
  if (!children) children = '·';

  return <EntryBadgeWrap className="badge">{children}</EntryBadgeWrap>;
};

const NavEntryWrap = styled.div`
  border-radius: 38%;
  overflow: hidden;
  position: relative;
  margin: 5px 0;

  .MuiIconButton-root {
    color: inherit;
  }

  &[data-active] {
    background-color: var(--theme-link-bg);
    color: var(--theme-link-text);
  }
`;

const NavEntry = observer(({ id, ...props }) => {
  const variants = {
    hidden: { x: -20, scale: 0.9, opacity: 0.5 },
    visible: { x: 0, scale: 1, opacity: 1 },
  };
  const isActive = store.sidebar.activeItem === id;

  const handleClick = () => {
    if (isActive) {
      store.sidebar.setActiveItem(null);
      return;
    }
    store.sidebar.setActiveItem(id);
  };

  return (
    <NavEntryWrap
      className={`NavEntry-${id}`}
      data-active={isActive ? 'active' : undefined}
      onClick={handleClick}
      variants={variants}
    >
      <IconButton {...props} size="large" />
    </NavEntryWrap>
  );
});

const SideBarWrap = styled.div`
  display: flex;
  height: 100%;

  aside {
    overflow-y: auto;
    scrollbar-color: auto var(--theme-bg);
    max-height: calc(100vh - 64px);
  }
`;

const FilterEntry = observer(() => {
  const filterActive = filtersStore.active.length > 0;

  return (
    <NavEntry id="filter">
      <FilterListOutlined />
      {filterActive && <EntryBadge />}
    </NavEntry>
  );
});

const UploadEntry = observer(() => {
  const changedFileCount = store.changedFiles.length;

  return (
    <NavEntry id="save">
      <CloudUploadOutlined />
      {changedFileCount > 0 && <EntryBadge>{changedFileCount}</EntryBadge>}
    </NavEntry>
  );
});

export default observer(() => {
  const items = {
    browser: <SideFilepicker />,
    'find-replace': <SideFindReplace />,
    filter: <SideFilter />,
    save: <SideSave />,
    settings: <SideSettings />,
  };
  const activeId = store.sidebar.activeItem;
  const active = items[activeId];

  return (
    <>
      <SideHeader shrunken={activeId === null} />
      <SideBarWrap>
        <Nav>
          <div className="start">
            <NavEntry id="browser">
              <FolderOutlined />
            </NavEntry>
            <FilterEntry />
            <NavEntry id="find-replace">
              <FindReplaceOutlined />
            </NavEntry>
            <Separator />
            <UploadEntry />
          </div>
          <div className="end">
            <PreviewButton />
            <Separator />
            <div style={{ padding: 0, marginTop: 10, marginBottom: 10 }}>
              <UserButton />
            </div>
            <NavEntry id="settings"><SettingsOutlined /></NavEntry>
          </div>
        </Nav>
        {activeId !== null && (
          <aside>
            {active}
          </aside>
        )}
      </SideBarWrap>
    </>
  );
});

