import styled from '@emotion/styled';
import {
  Code,
  Description,
  Folder,
  OpenInNewOutlined,
  UploadOutlined,
  SaveAltOutlined,
  TableChart,
  Toc,
  UndoOutlined,
} from '@mui/icons-material';
import { getEmojiFlag } from 'countries-list';
import { observer } from 'mobx-react';
import React from 'react';
import store from '../store';
import useContextMenu, { ContextItem } from './ContextMenu';

const FileActions = observer(({ file }) => {
  const { currentProject } = store;
  const historyUrl = `${currentProject.webUrl}/tree/${currentProject.ref}/${file.path}`;

  return (
    <>
      <ContextItem
        onClick={() => store.actions.run('revert', { file })}
        disabled={!file.modifiedAnything}
      >
        <UndoOutlined />
        Undo all changes
      </ContextItem>
      <ContextItem
        onClick={() => store.actions.run('import', { file })}
      >
        <UploadOutlined />
        Replace content with …
      </ContextItem>
      <ContextItem
        onClick={() => store.actions.run('export', { file })}
      >
        <SaveAltOutlined />
        Export as …
      </ContextItem>
      {!store.limitedEditPermission && (
        <ContextItem as="a" href={historyUrl} target="_blank" rel="noopener">
          <OpenInNewOutlined />
          Open in GitLab
        </ContextItem>
      )}
    </>
  );
});

const FileLinkWrap = styled.div`
  background-color: transparent;
  border: 0;
  color: ${p => p.color || 'inherit'};
  font-style: ${p => p.isLoaded ? 'normal' : 'italic'};
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 1;
  cursor: pointer;
  width: 100%;

  .text {
    text-overflow: ellipsis;
    word-break: keep-all;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    padding-bottom: 1px;
  }

  .icon {
    margin-right: 0.5em;
    font-size: 15px;
    font-style: normal;

    svg { font-size: inherit; }
  }
`;

const JsonIconWrap = styled.div`
  display: block;
  font-family: monospace;
  font-size: 11px;
  width: 15px;
  margin-top: -1px;
  padding-left: 1px;
`;
const JsonIcon = () => <JsonIconWrap>{'{}'}</JsonIconWrap>;

/**
  @param {{ file: File }} – the params
*/
export default observer(({ file, short = false, noFlag = false, ...rest }) => {
  const [onContextMenu, ContextMenu] = useContextMenu(<FileActions file={file} />);
  const p = store.currentProject;
  const iconMap = {
    folder: Folder,
    xml: Code,
    json: JsonIcon,
    csv: Toc,
    xlsx: TableChart,
    yaml: Description,
    default: Description,
  };

  const Icon = iconMap[file.gitlabFile.fileType] || iconMap['default'];
  const isDefaultLang = p.isFileDefaultLang(file);

  let color = 'inherit';
  if (file.modifiedAnything) color = 'var(--theme-warn)';
  if (file.toBeCreated) color = 'var(--theme-success)';
  if (file.hasConflicts) color = 'var(--theme-danger)';

  const title = short ? file.name.split('.').slice(0, -1).join('.') : file.name;
  const countryFlag = getEmojiFlag(file.country);
  const fullPath = `${file.directory}/${file.name}`;

  return (
    <FileLinkWrap
      color={color}
      type="button"
      isDefault={isDefaultLang}
      isLoaded={file.loaded}
      onContextMenu={onContextMenu}
      title={short ? fullPath : undefined}
      {...rest}
    >
      <div className="icon select-none">
        {!noFlag && countryFlag ? <span className="language">{countryFlag}</span> : <Icon /> }
      </div>
      <div className="text">{title}</div>
      <ContextMenu />
    </FileLinkWrap>
  );
});
