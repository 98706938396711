import { makeObservable, observable, computed, action, autorun } from 'mobx';
import localForage from 'localforage';
import { Account } from '~/../api/src/defs';
import api from '../api-client';
import Actions from './actions';
import projects from './projectsStore';
import userSettings from './userSettingsStore';
import preview from './previewStore';
import tabs, { FileTab } from './tabs';
import sidebar from './sidebar';
import filters from './filters';
import findReplace from './find-replace';
import fileBrowser from './file-browser';

import Project from './modules/project';
import Entry from './modules/entry';
import { File, LanguageFile, AssetFile } from './modules/files';

export { Project, Entry, FileTab, AssetFile };

type User = Account;

class Store {
  @observable ready = false;
  @observable tabs = tabs;
  @observable sidebar = sidebar;
  @observable actions = new Actions();
  @observable currentProject: Project;
  @observable localFiles: Set<File> = new Set();

  // shows the app update prompt if true. value is a function
  // that updates the app on call
  @observable swUpdatePrompt = null;

  // stores
  @observable projects = projects;
  @observable userSettings = userSettings;
  @observable preview = preview;
  @observable filters = filters;
  @observable findReplace = findReplace;

  @observable user: User = null;

  constructor() {
    makeObservable(this);
  }

  @computed get userIsAuthenticated() {
    return !!this.user;
  }

  @computed get currentFile() {
    if (!this.tabs.activeTab) return null;
    return this.tabs.activeTab.file;
  }

  @computed get limitedEditPermission() {
    return this.user?.superAdmin !== true;
  }

  @computed get currentEntries() {
    if (!this.tabs.activeTab || !(this.tabs.activeTab instanceof FileTab)) return null;
    if (this.tabs.activeTab.file instanceof LanguageFile !== true) return null;
    const file = this.tabs.activeTab.file as LanguageFile;
    if (!file.entries) return null;
    return this.filters.filterEntries(file.entries);
  }

  @computed get replaced() {
    if (this.currentEntries === null) return [];
    return this.findReplace.preview(this.currentEntries);
  }

  @computed get replacedMatches() {
    return this.replaced.filter(r => r.some(e => typeof e === 'object'));
  }

  @computed get relativeFindIndex() {
    let count = 0;
    for (let a = 0; a < this.findReplace.focusedIndex; a++) {
      const el = this.replaced[a];
      if (!el) return null;
      if (el.some(e => typeof e === 'object')) count++;
    }
    return count;
  }

  @computed get findResultCount() {
    if (this.replaced instanceof Error) return 0;
    return this.replacedMatches.length;
  }

  @computed get changedFiles() {
    return this.currentProject.files.filter(f => f.modifiedAnything);
  }

  @action async fetchUser() {
    const user = await api.get('/account').then(r => r.data) as Account;

    this.user = user;
    return user;
  }

  // close current project
  @action closeProject() {
    this.ready = false;
    this.currentProject = null;
    this.localFiles = new Set();
    this.tabs.tabs = [];
    this.filters.resetAll();
    this.findReplace.reset();
    this.sidebar.reset();
  }

  @action async openProject(id) {
    // close current project
    this.closeProject();

    // load project
    await this.fetchProject(id);
    await this.currentProject.fetchConfig();
    await this.currentProject.fetchFileList();
    // restore local state
    await Promise.all(
      this.currentProject.files
        .filter(f => f instanceof LanguageFile)
        .map(f => f.loadAutosave()),
    );
    // restore workspace
    await this.restoreWorkspace();
    this.ready = true;
  }

  @action setProject(p) {
    this.currentProject = p;
  }

  @action async fetchProject(id) {
    const project = await api.get(`gitlab/projects/${id}`)
      .then(res => new Project(res.data));
    this.setProject(project);
    return project;
  }

  @action async openFile(file, { tab = true, focus = true } = {}) {
    this.localFiles.add(file);
    if (tab) this.tabs.openFileTab(file, focus);
  }

  @action applyFindReplace = () => {
    const replaced = Array.from(this.replaced);
    this.currentEntries.forEach((entry, index) => {
      const replacement = replaced[index] as any;
      const newValue = replacement.map(r => {
        if (r.replacement !== undefined) return r.replacement;
        return r;
      }).join('');
      entry.setValue(newValue);
      return entry;
    });

    // removed for now to enable better multi-file editing
    // this.findReplace.find.value = '';
    // this.findReplace.replace.value = '';
    return this.currentEntries;
  };

  // commits all files in the current project that are marked to be committed
  @action async commitChanges(
    { message, files, branch = 'master' } :
    { message: string, files: File[], branch?: string },
  ) {
    const langFiles = files.filter(f => f instanceof LanguageFile) as LanguageFile[];
    const assetFiles = files.filter(f => f instanceof AssetFile) as AssetFile[];
    const langOps = langFiles.map(async file => {
      const { gitlabFile } = file;
      const content = await file.serializedEntries();

      return {
        action: 'update',
        file_path: gitlabFile.file_path,
        last_commit_id: gitlabFile.last_commit_id,
        content,
      };
    });
    const langActions = await Promise.all(langOps);
    const assetActions = await Promise.all(assetFiles.map(file => file.getCommitActions()));
    const actions = [
      ...langActions,
      ...assetActions.flat(),
    ];

    if (actions.length === 0) return;

    const res = await api.post(
      `gitlab-wrap/projects/${this.currentProject.id}/repository/commits`,
      {
        branch,
        commit_message: message,
        actions,
      },
    ).then(res => res.data);

    // clear change marks
    files.forEach(f => f.markCommitted(res.id));

    // clear local autosave
    await Promise.all(langFiles.map(f => f.autosave()));
  }

  @action async restoreWorkspace() {
    const save = await localForage.getItem(
      `ted.autosave.workspace.v1.${this.currentProject.id}`,
    ) as any;
    if (!save) return;

    console.log('restoring workspace', save);
    save.tabs.forEach(({ id, scrollTop = 0 }) => {
      const file = this.currentProject.files.find(f => f.uid === id);
      if (!file) return;
      if (file instanceof LanguageFile) file.setScrollTop(scrollTop);
      this.openFile(file, { focus: save.focusedTab === id });
    });
    this.sidebar.setActiveItem(save.sidebar.activeItem);
    this.sidebar.setCommit(save.sidebar.commit);
    fileBrowser.setOpenFolders(save.openFolders || []);
  }
}

const store = new Store();
export default store;

const settings = JSON.parse(localStorage.getItem('ted.userSettings'));
if (settings) store.userSettings.patchSettings(settings);

autorun(() => {
  localStorage.setItem('ted.userSettings', JSON.stringify(store.userSettings.settings));
});
