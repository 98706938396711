import { autorun, toJS } from 'mobx';
import localForage from 'localforage';

import store, { AssetFile } from './index';
import { LanguageFile } from './modules/files';
import { FileTab } from './tabs';
import fileBrowser from './file-browser';

// file content
autorun(() => {
  // note: the logic here was a computed in the file module at first. it was exposed as
  // a boolean. This does not work because autorun/reactions only get triggered
  // when the value *changes*. The boolean could stay true tho.
  Array.from(store.localFiles.values()).filter(genericFile => {
    // asset files currently can not be auto-saved
    if (genericFile instanceof AssetFile) {
      return false;
    }
    const { modifiedAnything, lastAutosave, contentHash } = genericFile as LanguageFile;

    // nothing was changed and there is no autosave, nothing to do
    if (!modifiedAnything && !lastAutosave) return false;

    // something was changed and there is no autosave yet. needs saving
    if (modifiedAnything && !lastAutosave) return true;

    // compare if content changed
    return (
      lastAutosave.localHash !== contentHash
    );
  }).forEach((file: LanguageFile) => {
    if (!file.autosave) {
      console.error('autosave: file has no autosave but should hve been filtered before', file);
      return;
    }
    file.autosave();
  });
});

// highlighted entries
autorun(() => {
  // only use current file avoids messy logic here but will break
  // if highlights can be set in non active file
  const { currentFile, ready } = store;
  if (!ready) return;
  if (!currentFile || !currentFile.loaded) return;

  // TODO: use instanceof check instead after ts conversion
  if (currentFile.entries === undefined) return;

  const highlighted = currentFile.entries.filter(e => e.meta.marked).map(e => e.id);
  localForage.setItem(`ted.autosave.marked.v1.${currentFile.uid}`, toJS(highlighted));
});

// save open tabs + scroll location, commit message, etc.
autorun(() => {
  const { tabs, sidebar, currentProject, ready } = store;

  if (!ready) return;
  if (!currentProject) return;

  const tabsSaveData = tabs.tabs
    .filter(tab => tab instanceof FileTab)
    .map((tab: FileTab) => ({ id: tab.id, type: 'file', scrollTop: tab.scrollTop }));

  const toSave = {
    projectId: currentProject.id,
    tabs: tabsSaveData,
    openFolders: Array.from(fileBrowser.openFolders.values()),
    focusedTab: tabs.activeTab?.id,
    sidebar: {
      activeItem: sidebar.activeItem,
      commit: toJS(sidebar.commit),
    },
  };

  localForage.setItem(`ted.autosave.workspace.v1.${currentProject.id}`, toSave);
});
